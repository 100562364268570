<template>
<div class="inputsn-container">
<van-nav-bar title="輸入購買序號" right-text="關閉" @click-right="$router.push('/')" />
<div class="inputsn-note">
注意事項:<br/>
本電子序號限兌換一次，一經兌換即不可重複使用。
</div>
<van-cell-group inset>
  <van-field v-model="value" label="序號" placeholder="請輸入購買序號" />
</van-cell-group>
 <div style="margin: 16px;">
    <van-button block type="primary" @click="handleInputSn">
      確認送出
    </van-button>
  </div>
</div>
</template>

<script setup>
function handleInputSn(){
    console.log('submit')
}
</script>

<style lang="less" scoped>
.inputsn-note{
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

</style>